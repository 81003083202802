<template>
  <base-section id="aboutourproduct">
    <base-section-heading color="grey lighten-2" title="Servicios" />

    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",
  data: () => ({
    cards: [
      {
        title: "Instalacion de automatismos",
        // subtitle: 'Subtitulo',
        text:
          "Realizamos automatismos para apertura y cierre de ventanas persianas y toldo.",
        callout: "1"
      },
      {
        title: "Reparación",
        // subtitle: 'Subtitulo',
        text:
          "Reparamos todo tipo de persianas y ventanas, así como sustitución de cintas y tambores, cambio de lamas, cambio de poleas, herrajes, cambio de recogedor, persinas descolgadas, persianas bloqueadas o atascadas, entre otros.",
        callout: "2"
      },
      {
        title: "Sustitución",
        // subtitle: 'Subtitulo',
        // text: 'Contenido...',
        callout: "3"
      },
      {
        title: "ventanas",
        // subtitle: 'Subtitulo',
        text:
          "Fabricamos instalamos ventanas de PVC y aluminio a medida, Asesoramiento personalizado y Presupuesto sin compromiso.",
        callout: "4"
      },
      {
        title: "Cambios de cintas",
        // subtitle: 'Subtitulo',
        text:
          "Con el paso del tiempo y el uso, las correas de las persianas se deterioran y pierden eficacia. Cuando empiezan a deshilacharse y debilitarse, es aconsejable sustituirlas para evitar roturas imprevistas y asegurarse así un correcto funcionamiento.",
        callout: "5"
      },
      {
        title: "Herrajes",
        // subtitle: 'Subtitulo',
        text:
          "Son piezas con distintas funciones una de ellas es la decorativa.",
        callout: "6"
      },
      {
        title: "Cambios de lamas",
        // subtitle: 'Subtitulo',
        // text: 'Forman la estructura interna de las persianas de una casa terminen por estropearse',
        callout: "7"
      },
      {
        title: "Manillas",
        // subtitle: 'Subtitulo',
        // text: 'Contenido...',
        callout: "8"
      },
      {
        title: "Cristales climalit",
        // subtitle: 'Subtitulo',
        text:
          "Son aquellas que incorporan un doble acristalamiento, o lo que es lo mismo, un vidrio doble con cámara.",
        callout: "9"
      }
    ]
  })
};
</script>
